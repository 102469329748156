import { apiServerUrl, ExternalApi } from "../apiService";
import { usePiniaStore } from "../../plugins/store";
import { keysToCamelCase } from "../../helpers/stringProcessors";
import {
    TransactionStatus,
    TransactionAdditionalInformation,
} from "../../helpers/interfaces/shared";

export const getTransactionStatus = async (
    token: string | null,
    identifier: string | null,
    alertAction = "refresh"
) => {
    /**
     * Internal API call to retrieve a transaction's status
     * @param  {[string | null]} token - auth0 token
     * @param  {[string | null]} identifier - transaction id
     * @param  {[string | null]} alertAction - display action alert or not, default to refresh
     * @return {[{TransactionStatus, null}]}
     */
    const pStore = usePiniaStore();

    const config = {
        url: `${apiServerUrl}/transactions/${identifier}/status`,
        method: "GET",
        headers: {
            "user-session-id": pStore.getSessionId,
            "Accept-Language": pStore.getLanguage,
            "Access-Control-Allow-Origin": apiServerUrl,
        },
    };

    if (token !== null) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }

    /** Define the behaviour if an error occurs during the API call */
    const errorHandlerConfig = {
        userAction: alertAction,
        sentryTransactionName: "getTransactionStatusAPI",
        redirectTo: "access-denied", // redirect to access denied
    };

    const externalApi = new ExternalApi();
    const { data } = await externalApi.callExternalApi(
        { config },
        errorHandlerConfig
    );

    if (data !== null) {
        const statusInformation: TransactionStatus = keysToCamelCase(data);
        return statusInformation;
    } else {
        return null;
    }
};

export const getTransactionAdditionalInformation = async (
    token: string | null,
    identifier: string,
    formName: string
) => {
    /**
     * Internal API call to retrieve additional transaction information. If
     * successful, it will save the information in the session storage
     * @param  {[string | null]} token - auth0 token
     * @param  {[string]} identifier - transaction uuid
     * @param  {[Number]} formName
     * @return {[{void}]}
     */
    const pStore = usePiniaStore();

    const config = {
        url: `${apiServerUrl}/transactions/${identifier}/additional-information?form-name=${formName}`,
        method: "GET",
        headers: {
            "user-session-id": pStore.getSessionId,
            "Accept-Language": pStore.getLanguage,
            "Access-Control-Allow-Origin": apiServerUrl,
        },
    };

    /** Attach the token to the authorisation headers if not null */
    if (token !== null) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }

    /** Define the behaviour if an error occurs during the API call */
    const errorHandlerConfig = {
        userAction: "refresh", // user needs to refresh the browser
        sentryTransactionName: "getTransactionAdditionalInformation",
    };

    const externalApi = new ExternalApi();
    const { data, error } = await externalApi.callExternalApi(
        { config },
        errorHandlerConfig
    );

    if (data !== null) {
        const additionalInformation: TransactionAdditionalInformation[] =
            keysToCamelCase(data);

        pStore.setPayerDetails({
            firstName: additionalInformation["payorFirstName"],
            lastName: additionalInformation["payorLastName"],
            address: {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                state: "",
                postalCode: "",
                countryCode: pStore.getCountryCode,
            },
        });
        /** Save the email address if it's returned in the endpoint's response */
        if ("emailAddress" in additionalInformation) {
            pStore.setEmailAddress(
                String(additionalInformation["emailAddress"])
            );
        }
        /** Update the supplementary data if it's returned in the endpoint's response */
        if ("supplementaryData" in additionalInformation) {
            pStore.updateSupplementaryData(
                additionalInformation.supplementaryData
            );
        }

        if ("returnUrl" in additionalInformation) {
            pStore.updatePartnerLinkInformation({
                returnUrl: String(additionalInformation["returnUrl"]),
            });
        }

        if ("partnerNotificationUrl" in additionalInformation) {
            pStore.updatePartnerLinkInformation({
                partnerNotificationUrl: String(
                    additionalInformation["partnerNotificationUrl"]
                ),
            });
        }

        if ("sendConfirmationEmail" in additionalInformation) {
            pStore.updatePartnerLinkInformation({
                sendConfirmationEmail: Boolean(
                    additionalInformation["sendConfirmationEmail"]
                ),
            });
        }

        if ("linkExpiryDate" in additionalInformation) {
            pStore.updatePartnerLinkInformation({
                linkExpiryDate: String(additionalInformation["linkExpiryDate"]),
            });
        }
    }

    return {
        data: data,
        error: error,
    };
};
