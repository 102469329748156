/**
 * External API request template with options that can be defined
 * individually in each request. Contains error handling.
 * :const apiServerUrl = External API URL
 */
import axios from "axios";
import { showAlert } from "@/helpers/errorHandling";
import {
    redirectTo404,
    redirectToAccessDenied,
    redirectToPaymentStatus,
} from "@/plugins/router";
import { usePiniaStore } from "@/plugins/store";
import { ErrorObject, ErrorHandlerConfig } from "@/helpers/interfaces/shared";
import { configSentryApiError } from "@/helpers/sentry";
import { translationExists } from "@/plugins/vi18n";

export const apiServerUrl = import.meta.env.VITE_API_URL;
class ErrorHandler {
    /**
     * Base class  for handling errors.
     */
    defaultAlertMessage: string;
    private redirect404: () => void;
    private redirectAccessDenied: () => void;

    constructor(
        message: string,
        redirect404: () => void = redirectTo404,
        redirectAccessDenied: () => void = redirectToAccessDenied
    ) {
        this.defaultAlertMessage = message;
        this.redirect404 = redirect404;
        this.redirectAccessDenied = redirectAccessDenied;
    }

    public showDefaultAlert(
        alertMessage: string = this.defaultAlertMessage,
        internationalisedMessage = true
    ) {
        /**
         * Displays the alert pop up to the user.
         * @param {[string]} alertMessage
         * @returns {[void]}
         */
        alertMessage = this.getCustomMessage(alertMessage);
        showAlert(alertMessage, internationalisedMessage);
    }

    private getCustomMessage(alertMessage: string): string {
        /**
         * Checks if the custom error message exists in the internationalisation list.
         * If it does not, it returns a default error message and logs a Sentry error.
         * @param {string} alertMessage - The alert message to check.
         * @returns {string} - The verified or default alert message.
         */
        if (translationExists(alertMessage)) {
            return alertMessage;
        } else {
            const defaultErrorMessage =
                "alerts.apiErrors.INTERNAL_SERVER_ERROR_RETRY";
            this.sendSentryError(
                "errorAlert",
                `The custom error code "${alertMessage}" does not exist in the internationalisation list`
            );
            return defaultErrorMessage;
        }
    }

    public sendSentryError(sentryTransactionName: string, sentryError: string) {
        /**
         * Sends an error alert to Sentry.
         * @param {[string]} sentryTransactionName
         * @param {[string]} sentryError
         * @returns {[void]}
         */
        configSentryApiError(sentryTransactionName, sentryError);
    }

    protected callRedirect404() {
        /**
         * Uses the router method to redirect the user to the
         * 404 Page. Can only be used inside the class & class
         * members.
         * @protected
         * @returns {[void]}
         */
        this.redirect404();
    }

    protected callRedirectAccessDenied() {
        /**
         * Uses the router method to redirect the user to the
         * Access Denied Page. Can only be used inside the class
         * & class members.
         * @protected
         * @returns {[void]}
         */
        this.redirectAccessDenied();
    }
}

class NotFoundError extends ErrorHandler {
    /**
     * Handles 404 errors.
     */
    handle(redirect: boolean, disable404Alert: boolean, ignore = false) {
        /**
         * Redirects the user to the 404 page if the redirect param
         * is true and displays the default error message.
         * @param {[boolean]} disable404Alert
         * @param {[boolean]} redirect
         * @param {[boolean]} [ignore=false] - does not throw the error if it's set to true
         * @returns {[void]}
         */
        if (!ignore) {
            if (!disable404Alert) this.showDefaultAlert();
            if (redirect) this.callRedirect404();
        }
    }
}

class AccessDeniedError extends ErrorHandler {
    /**
     * Handles access denied errors.
     */
    handle(redirect: boolean) {
        /**
         * Displays the default error message and redirects the user
         * to the access denied page if the redirect param is true.
         * @param {[boolean]} redirect
         * @returns {[void]}
         */
        this.showDefaultAlert();
        if (redirect) this.callRedirectAccessDenied();
    }
}

class TooManyRequestsError extends ErrorHandler {
    /**
     * Handles too many requests errors.
     */
    handle() {
        /**
         * Displays the default error message.
         * @returns {[void]}
         */
        this.showDefaultAlert();
    }
}

class NetworkError extends ErrorHandler {
    /**
     * Handles network errors.
     */
    handle() {
        /**
         * Displays the default error message.
         * @returns {[void]}
         */
        this.showDefaultAlert();
    }
}

class TimeOutError extends ErrorHandler {
    /**
     * Handles timeout exceeded errors.
     */
    handle() {
        /**
         * Displays the default error message.
         * @returns {[void]}
         */
        this.showDefaultAlert();
    }
}

class RequestAbortedError extends ErrorHandler {
    /**
     * Handles request aborted errors.
     */
    handle() {
        /**
         * Displays the default error message.
         * @returns {[void]}
         */
        this.showDefaultAlert();
    }
}

class UnexpectedError extends ErrorHandler {
    /**
     * Handles unexpected errors.
     */
    handle(sentryTransactionName: string, sentryError: string, ignore = false) {
        /**
         * Sends an error alert to Sentry and displays the default error
         * message to the user
         * @param {[string]} sentryTransactionName - method where error was triggered
         * @param {[string]} sentryError - formatted error
         * @param {[boolean]} [ignore=false] - does not throw the error if it's set to true
         * @returns {[void]}
         */
        if (!ignore) {
            this.sendSentryError(sentryTransactionName, sentryError);
            this.showDefaultAlert();
        }
    }
}

class PayloadTooLarge extends ErrorHandler {
    /**
     * Handles 413 errors.
     */
    handle(sentryTransactionName: string, sentryError: string) {
        /**
         * Sends an error alert to Sentry and displays the default error
         * message to the user
         * @param {[string]} sentryTransactionName - method where error was triggered
         * @param {[string]} sentryError - formatted error
         * @returns {[void]}
         */
        this.sendSentryError(sentryTransactionName, sentryError);
        this.showDefaultAlert();
    }
}

class BadRequest extends ErrorHandler {
    /**
     * Handles 400 errors.
     */
    handle(sentryTransactionName: string, sentryError: string) {
        /**
         * Sends an error alert to Sentry and displays the default error
         * message to the user
         * @param {[string]} sentryTransactionName - method where error was triggered
         * @param {[string]} sentryError - formatted error
         * @returns {[void]}
         */
        this.sendSentryError(sentryTransactionName, sentryError);
        this.showDefaultAlert();
    }
}

export class ExternalApi {
    /**
     * Handles requests made to external APIs
     */
    async callExternalApi(
        options: any,
        errorHandlerConfig: ErrorHandlerConfig
    ): Promise<{ data: any; error: any }> {
        /**
         * Calls external apis and returns the response. If an error occurs it
         * formats it, handles the behaviour based on the errorHandlerConfig param
         * and returns null.
         * @param {[any]} options - req. headers, method type
         * @param {[ErrorHandlerConfig]} errorHandlerConfig - behaviour on error
         * @returns {[Promise<{ data: any; }>]}
         */
        try {
            const response = await axios(options.config);
            const { data } = response;

            return {
                data,
                error: null,
            };
        } catch (error) {
            const formattedError = this.formatError(error);

            this.handleApiError(
                formattedError,
                errorHandlerConfig.userAction,
                errorHandlerConfig.sentryTransactionName,
                errorHandlerConfig.disable404Alert,
                errorHandlerConfig.redirectTo
            );

            return {
                data: null,
                error: error,
            };
        }
    }

    private formatError(error): ErrorObject {
        /**
         * Formats an error object into a standardized structure
         * for consistent error handling.
         * @private
         * @param {Error | AxiosError} error - The error object to be formatted.
         * @returns {ErrorObject} An object containing status, message, and identifier properties.
         */
        if (axios.isAxiosError(error)) {
            const axiosError = error;

            const { response } = axiosError;

            let status = "request status";
            let message = "http request failed";
            let identifier = "";
            let code = "";

            if (response && response.statusText) {
                status = String(response.status);
                message = response.statusText;
            }

            if (axiosError.message) {
                status = String(axiosError.status);
                message = axiosError.message;
            }

            if (
                response &&
                response.data &&
                response.data.message &&
                response.status
            ) {
                status = String(response.status);
                message = response.data.message;
            }

            if (response && response.data && response.data.identifier) {
                identifier = response.data.identifier;
            }

            if (response && response.data && response.data.code) {
                code = response.data.code;
            }

            return {
                status: status,
                message: message,
                identifier: identifier,
                code: code,
            };
        }

        return {
            status: "",
            message: error instanceof Error ? error.message : "",
            identifier: "",
            code: "",
        };
    }

    private handleApiError = (
        error: ErrorObject,
        userAction: string,
        sentryTransactionName: string,
        disable404Alert = false,
        redirectTo = ""
    ) => {
        /**
         * Handles errors from API calls, shows the alerts and redirects user if needed.
         * @param {ErrorObject} error - The error object to handle.
         * @param {string} userAction - User action after error (retry/refresh/ignore).
         * @param {string} sentryTransactionName - Method where error was triggered.
         * @param {string} [disable404Alert=false] - Does not display the 404 alert if set to true
         * @param {string} [redirectTo=""] - Where to redirect the user ["404", "access-denied", "payment-status"]
         * @returns {void} This function does not return a value.
         */
        const pStore = usePiniaStore();

        const { code, status, message } = error;
        // Sets the default error message based on the userAction
        const alertAction = userAction === "retry" ? "RETRY" : "REFRESH";
        const redirectToAccessDenied = redirectTo === "access-denied";
        const redirectTo404 = redirectTo === "404";

        /** Handles redirects to the Payment Status page if the identifier exists */
        if (
            error.hasOwnProperty("identifier") &&
            error.identifier !== undefined &&
            error.identifier !== ""
        ) {
            pStore.setPaymentId(error.identifier);
            if (redirectTo === "payment-status") {
                redirectToPaymentStatus(
                    String(pStore.getShortcode),
                    String(pStore.getFormName),
                    error.identifier
                );
            }
        }

        switch (status) {
            case "400": {
                const badRequestError = new BadRequest(
                    `alerts.apiErrors.${code}`
                );
                const sentryError = `400 error in API call: ${JSON.stringify(
                    error,
                    null,
                    2
                )}`;
                badRequestError.handle(sentryTransactionName, sentryError);
                break;
            }
            case "401":
            case "403": {
                const accessDeniedError = new AccessDeniedError(
                    `alerts.apiErrors.${code}`
                );
                accessDeniedError.handle(redirectToAccessDenied);
                break;
            }
            case "404": {
                const notFoundError = new NotFoundError(
                    `alerts.apiErrors.${code}`
                );
                notFoundError.handle(
                    redirectTo404,
                    disable404Alert,
                    userAction === "ignore"
                );
                break;
            }
            case "429": {
                new TooManyRequestsError(
                    "alerts.apiErrors.TOO_MANY_REQUESTS"
                ).handle();
                break;
            }
            case "413": {
                const payloadTooLarge = new PayloadTooLarge(
                    "alerts.apiErrors.FILE_TOO_LARGE"
                );
                const sentryError = `413 error in API call: ${JSON.stringify(
                    error,
                    null,
                    2
                )}`;
                payloadTooLarge.handle(sentryTransactionName, sentryError);
                break;
            }
            default: {
                const networkError = message === "Network Error";
                const timeoutExceeded = message === "timeout exceeded";
                const requestAborted = message === "Request aborted";

                if (networkError) {
                    new NetworkError("alerts.apiErrors.NETWORK_ERROR").handle();
                } else if (timeoutExceeded) {
                    new TimeOutError(
                        "alerts.apiErrors.REQUEST_TIMED_OUT"
                    ).handle();
                } else if (requestAborted) {
                    new RequestAbortedError(
                        "alerts.apiErrors.REQUEST_ABORTED"
                    ).handle();
                } else {
                    const unexpectedError = new UnexpectedError(
                        `alerts.apiErrors.INTERNAL_SERVER_ERROR_${alertAction}`
                    );
                    const sentryError = `Unexpected error in API call: ${JSON.stringify(
                        error,
                        null,
                        2
                    )}`;
                    unexpectedError.handle(
                        sentryTransactionName,
                        sentryError,
                        userAction === "ignore"
                    );
                }
                break;
            }
        }
    };
}
