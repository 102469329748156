import {
    PaymentMethod,
    SelectPaymentMethodItem,
} from "./interfaces/organisations";
import router from "../plugins/router";
import { usePiniaStore } from "../plugins/store";
import { configSentryError } from "./sentry";
import { formatAmountBasedOnLocaleAndCurrency } from "./amountProcessors";
import { keysToCamelCase } from "./stringProcessors";
import { hasValidProperty } from "./typeValidations";
import { getPaymentMethodsList } from "../apis/esendaV2/organisations";
import { handleQueryParamsError } from "./errorHandling";
import { getTransactionAdditionalInformation } from "../apis/esendaV2/transaction";

export const getFirstProvider = (paymentMethods: PaymentMethod[]) => {
    /**
     * Returns the first provider in the payments list based on the
     * lowest displayOrder property value.
     * @param {[PaymentMethod[]]} paymentMethods
     * @returns {[PaymentMethod]}
     */

    return paymentMethods.reduce(function (prev, current) {
        return prev.displayOrder < current.displayOrder ? prev : current;
    });
};

export const getPaymentMethodName = (provider: string) => {
    /**
     * Maps a payment provider to a payment method name.
     * @param {[string]} provider
     * @returns {[string]}
     */
    const paymentMethodsMapping = {
        "Instant Payment": ["yapily", "lean", "truelayer"],
        "Card ": ["opayo", "nuvei", "checkout"],
        "Bank Transfer": ["currencycloud"],
    };

    return Object.keys(paymentMethodsMapping).find((key) =>
        paymentMethodsMapping[key].includes(provider)
    );
};

export const redirectToPaymentMethod = (paymentMethods: PaymentMethod[]) => {
    const pStore = usePiniaStore();

    if (paymentMethods.length == 0) {
        router.push({
            name: "DisabledPaymentMethods",
            params: {
                shortcode: pStore.getShortcode,
                form: pStore.getFormName,
            },
        });

        configSentryError(
            "PaymentMethods",
            "redirectToPaymentMethod",
            `No payment enabled for user ${pStore.getEmailAddress}`
        );
    } else {
        router.push({
            name: "PaymentMethod",
            params: {
                shortcode: pStore.getShortcode,
                form: pStore.getFormName,
            },
        });
    }
};

export const getPaymentMethodCategory = (provider: string | null) => {
    /**
     * Maps a payment provider to a payment method category.
     * @param {[string]} provider
     * @returns {[string]}
     */

    if (provider === null) {
        return "";
    }
    const paymentMethodsMapping = {
        instant: ["yapily", "lean", "truelayer"],
        card: ["opayo", "nuvei", "checkout"],
        transfer: ["currencycloud"],
    };

    return Object.keys(paymentMethodsMapping).find((key) =>
        paymentMethodsMapping[key].includes(provider)
    );
};

export const getPaymentMethodsSelectorList = () => {
    /**
     * Converts the list of payment methods in a format accepted by Vuetify's
     * custom selector component. It formats the amount based on the currency
     * and the user's locale.
     * @returns {[value: string, title: string | undefined, props: { amount: string}]}
     *
     * @example
     * {
     *  value: "yapily",
     *  title: "Instant Payment",
     *  props: {
     *      amount: "£123.12"
     *    }
     * }
     */
    const pStore = usePiniaStore();

    const locale = pStore.getLanguage !== null ? pStore.getLanguage : "en-GB";
    const paymentMethods = pStore.getPaymentMethods;

    const formattedPaymentMethodsList: SelectPaymentMethodItem[] = [];
    if (paymentMethods !== null) {
        for (const method of paymentMethods) {
            const amount = formatAmountBasedOnLocaleAndCurrency(
                locale,
                method.currencyCode,
                method.amount
            );
            formattedPaymentMethodsList.push({
                value: method.paymentMethod,
                title: getPaymentMethodName(method.paymentMethod),
                props: { amount: amount },
            });
        }
    }
    return formattedPaymentMethodsList;
};

export const getPaymentMethodAmount = (formatAmount = true) => {
    /**
     * Uses the list of payment methods to return the localised formatted amount
     * which corresponds to the payment method selected.
     * @param {[boolean]} formatAmount - default true
     * @returns {[string]}
     */
    const pStore = usePiniaStore();

    const provider = pStore.getSelectedProvider;

    const locale = pStore.getLanguage !== null ? pStore.getLanguage : "en-GB";

    const paymentMethods = pStore.getPaymentMethods;

    if (paymentMethods !== null) {
        const paymentMethodUsed = paymentMethods.find(
            (obj) => obj.paymentMethod === provider
        );

        if (paymentMethodUsed !== undefined) {
            if (formatAmount) {
                const amount = formatAmountBasedOnLocaleAndCurrency(
                    locale,
                    paymentMethodUsed.currencyCode,
                    paymentMethodUsed.amount
                );
                return amount;
            } else {
                return paymentMethodUsed.amount;
            }
        } else {
            return "";
        }
    }
};

export const getPaymentMethodProviderConfig = (paymentMethod: string) => {
    /**
     * Returns the provider configuration based on the payment method
     * given. If no config values are found, it returns null.
     * @param {[string]} paymentMethod
     * @returns {[any]}
     */
    const pStore = usePiniaStore();
    const paymentMethods = pStore.getPaymentMethods;

    if (paymentMethods !== null) {
        const paymentMethodUsed = paymentMethods.find(
            (obj) => obj.paymentMethod === paymentMethod
        );

        if (paymentMethodUsed !== undefined) {
            return paymentMethodUsed.providerConfig;
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export const getPaymentMethodIcon = (paymentMethod: string) => {
    /**
     * Maps the payment method to an mdi-icon
     * @param {[string]} paymentMethod
     * @returns {[string]}
     */

    const iconMapping = {
        "Instant Payment": "mdi-lightning-bolt",
        "Card ": "mdi-credit-card",
        "Bank Transfer": "mdi-web",
    };

    return paymentMethod in iconMapping ? iconMapping[paymentMethod] : "";
};

export const getPaymentMethodIconColor = (paymentMethod: string) => {
    /**
     * Maps the payment method icon to a color.
     * @param {[string]} paymentMethod
     * @returns {[string]}
     */

    const iconMapping = {
        "Instant Payment": "warning",
        "Card ": "success",
        "Bank Transfer": "info",
    };

    return paymentMethod in iconMapping ? iconMapping[paymentMethod] : "";
};

const getPaymentInformation = async (
    formName: string,
    identifier: string,
    countryCode: string,
    amount: number
) => {
    /**
     * Makes a call to the BE API to retrieve additional transaction information.
     * If successful, it then makes a call to retrieve the list of available
     * payment methods. It waits for both of them to complete before redirecting
     * the user to the payment method page.
     * @param {[string]} formName
     * @param {[string]} identifier - transaction id
     * @param {[string]} countryCode
     * @param {[number]} amount
     * @returns {[void]}
     */
    try {
        const { error } = await getTransactionAdditionalInformation(
            null,
            identifier,
            formName
        );

        if (error == null) {
            await getPaymentMethodsList(null, countryCode, amount);
        }
    } catch (error) {
        configSentryError(
            "OneTimeLink",
            "getPaymentInformation",
            `Error while calling the transaction information or payment methods API: ${error}`
        );
    }
};

export const handleOneTimeLinkLogic = async (
    form: string,
    identifier: string,
    queryObject: any
) => {
    /**
     * Parses and stores the link query params and calls the payment
     * methods api to continue the user journey
     * @param {[any]} queryObject
     * @returns {[void]}
     */
    const pStore = usePiniaStore();

    /** Set the form name */
    if (form !== "null" && form !== "undefined") {
        pStore.setFormName(form);
    } else {
        handleQueryParamsError(form, "form");
        return;
    }

    const supplementaryData = {};
    /** Save description */
    if (hasValidProperty(queryObject, "description")) {
        supplementaryData["description"] = queryObject.description as string;
    }
    pStore.updateSupplementaryData(supplementaryData);

    /** Set the payment id */
    if (form !== "null" && identifier !== "undefined") {
        pStore.setPaymentId(identifier);
    } else {
        handleQueryParamsError(identifier, "id");
        return;
    }

    const formattedQueryObject = keysToCamelCase(queryObject);
    /** Save country code */
    if (hasValidProperty(formattedQueryObject, "country")) {
        const countryCode = formattedQueryObject.country as string;
        pStore.updateCountryCode(countryCode);
    } else {
        handleQueryParamsError(queryObject, "country");
        return;
    }

    // TODO deprecate this param and save the email address from the additional information form
    /** Save email address */
    if (hasValidProperty(formattedQueryObject, "emailAddress")) {
        const emailAddress = formattedQueryObject.emailAddress as string;
        pStore.setEmailAddress(emailAddress);
    } else {
        handleQueryParamsError(queryObject, "emailAddress");
        return;
    }

    /** Set amount */
    if (hasValidProperty(formattedQueryObject, "amount")) {
        const amount = parseFloat(formattedQueryObject.amount as string);
        /** Get the the additional user information and the payment method list and continue the user journey */
        pStore.setSelectedProvider(null);
        await getPaymentInformation(
            form,
            identifier,
            pStore.getCountryCode as string,
            amount
        );
    } else {
        handleQueryParamsError(queryObject, "amount");
        return;
    }
};

export const getNoOfAvailablePaymentMethods = (): number => {
    /**
     * Gets the length of the payment method object and returns
     * the no of payment methods available
     * @returns {string}
     */
    const pStore = usePiniaStore();
    return pStore.getPaymentMethods === null
        ? 0
        : pStore.getPaymentMethods.length;
};

export const getProviderName = (): string | null => {
    /**
     * Returns the selected provider name.
     * @returns { string | null }
     */
    const pStore = usePiniaStore();
    return pStore.getSelectedProvider;
};

export const getPaymentMethodCurrencyCode = () => {
    /**
     * Uses the list of payment methods to return the currency code
     * which corresponds to the payment method selected. It defaults
     * to GBP if no currency code is found.
     * @returns {[string]}
     */

    const pStore = usePiniaStore();

    const provider = pStore.getSelectedProvider;

    const paymentMethods = pStore.getPaymentMethods;

    if (paymentMethods !== null) {
        const paymentMethodUsed = paymentMethods.find(
            (obj) => obj.paymentMethod === provider
        );

        if (paymentMethodUsed !== undefined) {
            return paymentMethodUsed.currencyCode;
        } else {
            return "GBP";
        }
    }
};
