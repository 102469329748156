import { createI18n } from "vue-i18n";

const i18n = createI18n({
    locale: "en-GB",
    fallbackLocale: "en-GB",
    allowComposition: true,
    messages: {
        "en-GB": {
            default: {
                notFound: "We can't find the page you are looking for.",
                disabledPaymentMethods:
                    "We are unable to collect your payment at this time. You will receive a ticket from our support team with more details shortly.",
                accessDeniedTitle: "Access denied",
                accessDeniedMessage:
                    "Unauthorised access. You do not have the necessary permissions to access this page. If you believe this is an error, please contact {supportEmail} for assistance.",
                supportEmail: "support{'@'}esenda.com",
                or: "Or",
                here: "here",
                multiplePaymentMethodsLabel:
                    "payment methods available. Choose the payment method below.",
                onePaymentMethodLabel: "payment method available",
                empty: "",
            },
            alerts: {
                unexpectedErrorWithRefreshAction:
                    "We have encountered an unexpected issue. Please refresh the page to continue.",
                unexpectedErrorWithRetryAction:
                    "We have encountered an unexpected issue. Please try again.",
                validationError:
                    "Please make sure that all the required fields are filled out correctly before continuing.",
                cardDetailsError:
                    "Please ensure that your card details are correct.",
                noInternetConnection:
                    "No internet connection. Please check your network connection.",
                requestDenied:
                    "The time allocated for viewing this resource has expired.",
                tooManyRequests: "Too many requests, please try again later.",
                sessionTimeout: "Your session has timed out.",
                apiErrors: {
                    BAD_REQUEST:
                        "Invalid request: Please check your input and try again. Contact support if the issue persists.",
                    UNAUTHORIZED: "Access to this resource is denied.",
                    FORBIDDEN:
                        "You do not have authorisation to view these resources. If you believe this is an error, please contact support.",
                    NOT_FOUND:
                        "The resource you were looking for could not be found.",
                    METHOD_NOT_ALLOWED:
                        "Action not allowed: This request method is not supported. Please contact support if you need assistance.",
                    INTERNAL_SERVER_ERROR:
                        "We have encountered an unexpected issue. Please try again.",
                    INTERNAL_SERVER_ERROR_REFRESH:
                        "We have encountered an unexpected issue. Please refresh the page to continue.",
                    INTERNAL_SERVER_ERROR_RETRY:
                        "We have encountered an unexpected issue. Please try again.",
                    UNKNOWN_ERROR_REFRESH:
                        "We have encountered an unexpected issue. Please refresh the page to continue.",
                    UNKNOWN_ERROR_RETRY:
                        "We have encountered an unexpected issue. Please try again.",
                    TOO_MANY_REQUESTS:
                        "Too many requests, please try again later.",
                    NETWORK_ERROR:
                        "No internet connection. Please check your network connection.",
                    FILE_TOO_LARGE:
                        "File too large. Please upload a smaller one or contact support for assistance.",
                    SUCCESSFUL_DOWNLOAD:
                        "The file has been exported successfully.",
                    REQUEST_DENIED:
                        "The time allocated for viewing this resource has expired.",
                    ORGANISATION_NOT_FOUND:
                        "The organisation could not be found.",
                    FORM_NOT_FOUND: "The form could not be found.",
                    INVALID_STATE:
                        "Invalid state code for the selected country.",
                    EXPIRED_PAYMENT_LINK:
                        "The payment link you are trying to access has expired and cannot be used. Please request a new one if needed.",
                    REQUEST_TIMED_OUT:
                        "The request is taking longer than expected. Please refresh to try again.",
                    REQUEST_ABORTED:
                        "The request was aborted. Please check your connection and try again. If the problem persists, contact our support team.",
                },
            },
            statusDescriptions: {
                SUCCESS: "SUCCESS",
                FAILED: "FAILED",
                PROCESSING: "PROCESSING",
                ACCEPTED: "ACCEPTED",
                REFUNDED: "REFUNDED",
                UNKNOWN: "UNKNOWN",
                reference: "Reference",
                success:
                    "Your payment was successful. You will receive an email confirmation shortly.",
                failed: "We have been unable to process your payment. Please retry with an alternative payment method, or contact support on {supportEmail} for further assistance.",
                processing:
                    "We are processing your payment, you may now close this page. All payment updates will be sent to your email.",
                processingBankTransfer:
                    "Bank transfer payments payments may take a few days to complete, depending on your country and bank. You may now continue and will receive an email confirmation once your payment has been received.",
                accepted:
                    "Your payment has been accepted. You will receive a confirmation email once the funds have cleared. This process will take no longer than two hours. You can keep this page open to receive updates regarding your payment status.",
                refunded: "Your payment has been successfully refunded.",
                default: "",
                redirectToPartner:
                    "You will be redirected to our partner's page in {empty} seconds. Click 'Continue' if you wish to be redirected straight away.",
                continueOnDesktopTitle: "You are done here!",
                continueOnDesktopDescription:
                    "Go back to your desktop browser.",
                paymentMethod: "Payment method",
                endingIn: "Ending in",
                bankTransferInformation:
                    "We will be unable to confirm the receipt of your payment if you have used a different payment details. Please contact {supportEmail} if your payment does not match the details shown below.",
            },
            bankTransferDetails: {
                nextStepsTitle: "Next Steps",
                nextStepsInstructions:
                    "Please now use the details below to make a payment through your bank.",
                emailConfirmation:
                    "A payment confirmation receipt will be emailed to you following your payment.",
                amountTitle: "Amount",
                referenceTitle: "Reference",
                referenceInfoMessage:
                    "It is important to use the reference as detailed here.",
                beneficiaryTitle: "Beneficiary",
                bankNameTitle: "Bank name",
                bankAddressTitle: "Bank address",
                localPaymentsTitle: "Local Payments",
                localPaymentsInstructions:
                    "Use these details when making a payment from a bank account within the UK.",
                internationalPaymentsTitle: "International Payments",
                internationalPaymentsInstructions:
                    "Use these details when making a payment from a bank account outside of the UK.",
                sepaPaymentInstructions:
                    "Please now use the details below to make a {sepa} payment through your bank.",
                paymentReferenceWarningTitle: "Payment reference",
                paymentReferenceWarning:
                    "Use this payment reference to avoid additional charges due to payment delays.",
                dobInfoMessage:
                    "Your date of birth may be required by our banking partner to complete compliance checks after funds have been received. This field is optional, however, if a compliance query is raised you will receive an email from {supportEmail} asking for this additional information.",
                excludingFeesMessage:
                    "If your bank charges transaction fees, please ensure that you cover these charges to avoid an outstanding balance on your account",
                redirectToPartner:
                    "You will be redirected to our partner's page in {empty} seconds. Click 'Continue' if you wish to be redirected straight away.",
                step1Title: "Enter your billing details",
                step2Title: "Bank transfer details",
                step3Title: "Redirect to partner",
                payLater:
                    "Please kindly take note of the bank details if you plan to pay at a later date. Once your payment has been received you will receive a confirmation email.",
                payLaterRedirect:
                    "If you would like to pay later, you may have to start your payment journey again. When you are ready to make a payment, please follow the same steps and you will be provided with a new payment reference. Following your payment, please select the 'Payment made' button.",
                sepaInformation:
                    "{icon} {type} payments are faster and have lower fees within the EU. Please ensure you select {type} as the payment method.",
                sepaIbanInformation:
                    "{icon} For {sepa} payments only. Do not use for {swift} transfers.",
            },
            instantPayment: {
                clickOnAInstitution:
                    "Select your bank from the list. If your bank is not shown, this will be due to limits they have set for this payment method. Alternative payment methods can be selected above.",
                noInstitutionFound:
                    "We could not find a bank that matches your search term.",
                amountBelowMinimumLimit:
                    "The payment amount is below the bank's {limit} minimum limit.",
                amountAbovePaymentLimit:
                    "The payment amount exceeds the bank's {limit} limit",
                noInstitutionsAvailable:
                    "No banks available. Please select a different payment method.",
                loadingInstitutions: "Loading banks. Please wait.",
                yapily: {
                    step1Title: "Choose a bank",
                    step2Title: "Consent details",
                    step3Title: "Scan QR code",
                    continueInstruction:
                        "Click the 'Continue on desktop' option",
                    startingPayment: "The payment is starting. Please wait.",
                    partnerInformation:
                        "We have partnered with {partner} to securely initiate payment from your account at {bankName}.",
                    paymentTotal: "Payment total",
                    payeeDetails: "Payee details",
                    payeeName: "Payee name:",
                    payeeAccountNumber: "Payee account number:",
                    payeeSortCode: "Payee account sort code:",
                    paymentReference: "Payment reference:",
                    termsAndConditions:
                        "By using the service, you agree to Yapily Connect initiating this payment and its {terms} and {privacy}.",
                    payInstruction: "Pay faster using your bank's mobile app",
                    scanInstruction: "Use your phone's camera to scan the code",
                    keepPageOpenInstruction:
                        "Keep this page open if you've scanned the QR code",
                },
                truelayer: {
                    step1Title: "Choose a bank",
                    step2Title: "Payer information",
                },
            },
            validations: {
                required: "Value is required",
                maxLength: "The maximum length allowed is {max}",
                firstNameValidation: "",
                notValid: "Not a valid value",
                stateTooLong: "The state can only contain two characters",
                email: "Value is not a valid email address",
                invalidPostalCode:
                    "Not a valid value. Please use only letters, numbers, and spaces.",
            },
            validationErrors: {
                incompleteCvc: "Incomplete CVV/CVC",
                incompleteDate: "Incomplete date",
                dateInPast: "Card expiration date is in the past",
                invalidExpirationYear: "Invalid year value",
                incompleteCardNumber: "Incomplete card number",
                incorrectCardNumber: "Incorrect card number",
            },
            cardPayment: {
                cardFieldLabel: "Credit or debit card",
            },
            labels: {
                "": "",
                empty: "",
                back: "Back",
                cancel: "Cancel",
                confirm: "Confirm",
                continue: "Continue",
                printReceipt: "Print receipt",
                newPayment: "New payment",
                redirectNow: "Redirect now",
                paymentMade: "Payment made",
                payLater: "I will pay later",
                notifyUs: "Notify us",
                showBankDetails: "Show bank details",
                printBankDetails: "Print bank details",
                continueOnDesktop: "Continue on desktop",
                authenticate: "Authenticate",
                authenticateWithGoogle: "Authenticate with Google",
                continueWithoutAuthentication:
                    "Continue without authentication",
                search: "Search",
                typeInstitutionName: "Type an institution name...",
                payerFirstName: "Payer first name",
                payerLastName: "Payer last name",
                dob: "Date of birth",
                country: "Country",
                addressSearch: "Address search",
                typeAddress: "Type your {country} billing address...",
                enterAddressManually: "Enter address manually",
                addressLine1: "Address line 1",
                addressLine2: "Address line 2",
                addressLine3: "Address line 3",
                state: "State",
                city: "City",
                postalCode: "Postal code",
                emailAddress: "Email address",
                clickToSelect: "Click to select a value",
                typeCountry: "Type the country...",
                dynamic: {
                    Amount: "Amount",
                    "Payment amount": "Payment amount",
                    "Pupil name": "Pupil name",
                    "Student full name": "Student full name",
                    "Student id": "Student id",
                    "Student code": "Student code",
                    "Student number": "Student number",
                    "Pupil number": "Pupil number",
                    "Payment origin country": "Payment origin country",
                    "Invoice number": "Invoice number",
                    "Date of birth (DD/MM)": "Date of birth (DD/MM)",
                    "Date of birth (dd/mm)": "Date of birth (DD/MM)",
                    "Student date of birth (DD/MM)":
                        "Student date of birth (DD/MM)",
                    "Student date of birth (ss/mm)":
                        "Student date of birth (DD/MM)",
                    "Payment reference": "Payment reference",
                    Reference: "Reference",
                    Description: "Description",
                    "Donor name": "Donor name",
                    Nursery: "Nursery",
                    "Pupil date of birth": "Pupil date of birth",
                },
            },
        },
    },
});

export const translationExists = (key: string): boolean => {
    /**
     * Checks if the key exist in the list of internationalised messages.
     * @param {string} key
     * @returns {boolean}
     */
    return i18n.global.te(key);
};

export default i18n;
